import { BundleDefinition, BundleLocationWithFallback } from "@athena/cdn-types";
import { createCache } from "./cache";
import { createBundleName } from "./name-helpers";
import { getLocation } from "./configuration";
import { getGlobalData } from "./global-data";
import { NUMBER_OF_FETCH_RETRIES } from "./constants";
import { incrementErrorCountCookie, shouldUseFallbackAsDefault } from './cookies';

// Temporary re-definition of the meta file, until it can be exported from 
// @athena/nimbus-core. Right now, its defined in @athena/nimbus-api, which is
// not a published package.
export interface NimbusMetaFile {
  metaVersion: string;
  name: string;
  version: string;
  modules: string | Record<string, string>;
  cssNamespace: string;
  assets: {
    type: 'js' | 'css';
    filename: string;
  }[];
  dependencies?: Record<string, {
    version: string;
    assets: {
      type: 'js' | 'css';
      filename: string;
    }[];
    dependencies?: Record<string, {
      version: string;
      assets: {
        type: 'js' | 'css';
        filename: string;
      }[];
    }>;
  }>;
}

/**
 * Cache to store meta after its loaded.
 */
export const MetaCache = createCache<BundleDefinition, Promise<NimbusMetaFile>>("Meta", createBundleName);

/**
 * Get the meta data for a bundle
 *
 * @param bundle The bundle definition
 * @returns A promise that resolves to the bundles meta data.
 */
export function getMeta(bundle: BundleDefinition): Promise<NimbusMetaFile> {

  return MetaCache.getDefault(bundle, () => {
    const location = getLocation(bundle);

    // TODO: Once https://github.com/athenahealth/athena-require/pull/1
    // is added to athenanet's version of athena-require, we can try to
    // import a pre-defined meta and only fetch if its not defined:
    // return import(`cdn-meta: ${bundleName}`)
    //     .catch(() => fetchMetaFile(location.metaFileURL))
    //     .then(validateMeta);

    return fetchMetaFile(location, {
      useFallback: shouldUseFallbackAsDefault(),
      numberOfAttempts: NUMBER_OF_FETCH_RETRIES,
    }).then(({ meta, useFallback }) => ({
      meta: validateMeta(meta),
      useFallback,
    })).then(({ meta, useFallback }) => {
      // The actual bundle described by the meta file might be different
      // than the bundle requested (This happens when the requested version
      // is a tagged version like DEFAULT). We need be sure to use the
      // loaded name and version, and re-get the location before adding
      // data to the global cache; otherwise the data will be cached under
      // the wrong key.
      const globalData = getGlobalData();
      const loadedBundle: BundleDefinition = {
        cdnName: bundle.cdnName,
        packageName: meta.name,
        version: meta.version,
      };
      const loadedLocation = getLocation(loadedBundle);

      globalData.set(
        loadedBundle, 'cssNamespace',
        meta.cssNamespace
      );
      globalData.set(
        loadedBundle, 'publicPath',
        useFallback
          ? loadedLocation.fallback!.relativeRoot
          : loadedLocation.relativeRoot
      );
      globalData.set(
        loadedBundle, 'useFallback',
        useFallback
      );

      return meta;
    }).catch((error) => {
      throw new Error(`Failed to fetch metafile: ${error.message}`);
    });
  });
}

interface FetchMetaFileOptions {
  noCache?: boolean;
  useFallback?: boolean;
  numberOfAttempts: number;
}

interface MetaFileResponse {
  meta: unknown;
  useFallback: boolean;
}

/**
 * Helper to fetch the meta file from a remote URL.
 *
 * @param location The location of the meta file
 * @param options.noCache Set to true to disable the browsers caching for this request
 * @param options.useFallback Set to true to use the fallback URL
 * @param options.numberOfAttempts Set to the number of times we should try before giving up
 * @returns A promise for the JSON content of the url
 */
async function fetchMetaFile(location: BundleLocationWithFallback, options: FetchMetaFileOptions): Promise<MetaFileResponse> {
  const url = (location.fallback && options.useFallback)
    ? location.fallback.metaFileURL
    : location.metaFileURL;

  try {
    const response = await fetch(url, {
      cache: options.noCache ? 'no-cache' : 'default',
    });
    return {
      meta: await handleMetaFileResponse(response),
      useFallback: !!options.useFallback,
    }
  }
  catch (error) {
    if (options.numberOfAttempts && options.numberOfAttempts > 1) {
      return fetchMetaFile(location, {
        ...options,
        noCache: true,
        numberOfAttempts: options.numberOfAttempts - 1,
      });
    }
    else if (location.fallback && !options.useFallback) {
      // We've failed to fetch from the default, so we're falling back
      // Increment the counter for number of times we've done this
      incrementErrorCountCookie();
      return fetchMetaFile(location, {
        ...options,
        numberOfAttempts: NUMBER_OF_FETCH_RETRIES,
        useFallback: true,
      });
    }
    else {
      throw error;
    }
  }
}


/**
 * Helper method to handle the response from the meta file request. Used to
 * throw meaningful errors when the meta file could not be loaded or parsed.
 * 
 * @param response The response object from a fetch request
 */
async function handleMetaFileResponse(response: Response): Promise<NimbusMetaFile> {
  if (response.status === 404) {
    throw new Error("Meta file not found");
  }
  else if (response.status > 400) {
    throw new Error(`Unknown error when loading meta file: ${await response.text()}`)
  }

  try {
    return await response.json();
  }
  catch (error) {
    throw new Error(`Invalid Meta File: ${error}`);
  }
}

/**
 * Validates the meta data.
 *
 * @todo Need to implement this.
 *
 * @param raw The raw data returned from the meta data
 * @returns The parsed and validated metadata
 * @throws When the meta data is unparsable and unrecoverable.
 */
function validateMeta(raw: unknown): NimbusMetaFile {
  // TODO.
  return raw as NimbusMetaFile;
}
