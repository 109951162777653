import { AssetOptions, BundleDefinition } from "@athena/cdn-types";
import { STAGE_LAUNCH_DARKLY_CLIENT_SIDE_ID, PROD_LAUNCH_DARKLY_CLIENT_SIDE_ID } from './constants';
import { getLocation } from "./configuration";
import { RT } from '@athena/rt-ld-js-sdk';

/**
 * Helper method to get the application version based on toggleEntity or
 * Launch Darkly user object.
 *
 * @param options Additional options for how to load assets
 * @param bundle 
 * @returns the application's version
 */
export async function getApplicationVersion(options: AssetOptions, bundle: BundleDefinition): Promise<string> {
  const toggleEntity = options.toggleEntity ? options.toggleEntity : { key: bundle.packageName };
  let applicationVersion = '';
  let flagName = '';

  if (toggleEntity.flag) {
    flagName = toggleEntity.flag;
    delete toggleEntity["flag"];
  } else {
    flagName = getApplicationFlagName(bundle.packageName);
  }

  const ldClientID = getLDClientID(bundle);
  const RTClient = new RT(ldClientID, toggleEntity);

  await RTClient.toggleState(flagName, 'DEFAULT')
    .then((value) => {
      applicationVersion = value;
    });
  return applicationVersion;
}

/**
 * Helper method to Converts an ordinary repository name to the standard 
 * launch darkly toggle version key
 * Examples:                                                                                                                                                    
 *               "Some App Name" -> "SOME_APP_NAME_VERSION"                                                                                                           
 *               "@athena/my-app" => "ATHENA__MY_APP_VERSION" 
 *
 * @param appName The string name to convert
 * @returns The converted default toggle name
 */
export function getApplicationFlagName(appName: string): string {
  let flagName;
  flagName = getEscapedName(appName).toUpperCase();
  flagName = flagName.replace(/[^a-zA-Z0-9_]/g, '_');
  flagName = flagName.concat('_VERSION');
  return flagName;
}

/**
 * Helper method to Converts an ordinary repository name to one appropriate for
 * Nimbus storage (lowercase, numeric, and underscore only)
 * This is the same as the standard rollout toggle version key, but lower case.
 * Examples:                                                                                                                                                    
 *               "Some App Name" -> "some_app_name"
 *               "@athena/my-app" -> "athena__my-app"
 *
 * @param appName The string name to convert
 * @returns escaped name
 */
export function getEscapedName(appName: string): string {
  appName = appName.toLowerCase();
  appName = appName.replace(/^\@/g, ''); // Remove leading @s
  appName = appName.replace(/\//g, '__'); // Replace /s for scoped packages with __
  appName = appName.replace(/ /g, '_'); // Replace spaces with _
  return appName;
}

/**
 * Helper method to identify the LD Clientid based on bundle's
 * CDN url.
 *
 * @param bundle 
 * @returns Client side id of LD
 */
export function getLDClientID(bundle: BundleDefinition): string {
  const metaLocation = getLocation(bundle);
  if (metaLocation && metaLocation.metaFileURL && metaLocation.metaFileURL.includes("cdn-stage")) {
    return STAGE_LAUNCH_DARKLY_CLIENT_SIDE_ID;
  }
  return PROD_LAUNCH_DARKLY_CLIENT_SIDE_ID;
}