import { parseBundleModuleName } from './name-helpers';
import { loadPrimaryAsset, prefetchAssets, loadAssets } from './assets';

/**
 * Interface describing an element that can be used as a host for assets. The
 * only requirement is that we can append elements to it. We create an interface
 * here because ShadowRoots can be used as an asset host, but are not
 * instances of HTMLElement.
 */
export interface AssetHost {
  appendChild(node: HTMLElement): void;
}

/**
 * A structured bundle definition
 */
export interface BundleDefinition {
  /**
   * The name of the CDN that contains this bundle
   */
  cdnName: string;
  /**
   * The name of the package of the bundle
   */
  packageName: string;
  /**
   * The version of the bundle
   */
  version?: string;
}

/**
 * A structured version entity definition
 */
export interface VersionEntityDefinition {
  /**
   * A unique string associated with the Launch Darkly Flag to identify the version
   */
  key: string;
  /**
   * Any custom attributes associated with the Launch Darkly Flag to identify the version
   */
  custom?: CustomEntityDefinition;
  /**
   * Application's Launch Darkly Flag
   */
  flag?: string;
}

/**
 * A structured custom entity definition
 */
export interface CustomEntityDefinition {
  /**
   * Any custom attributes associated with the Launch Darkly Flag to identify the version
   */
  [key: string]: string | number;
}

/** Additional options available when loading or preloading bundles */
export interface LoadOptions extends BundleDefinition {

  /**
   * An optional DOM element that will be used as the container for CSS styles.
   *
   * The primary use case for this is to enable use of the shadow dom. Pass
   * a child of the shadow root (or the shadow root itself), and the style
   * tags will be added to it instead of document.head, isolating the app
   * styles from any pre-existing styles on the page.
   *
   * While intended to be a DOM element, any object with a `appendChild` method
   * can also be used.
   *
   * **Important Note**: When this property is specified, the bundle loader's
   * asset caching for CSS files is ignored. This means that the bundle
   * loader will not prevent duplicate CSS files from being attached to the
   * DOM. This is necessary for use with the shadow dom, because every shadow
   * root needs its own CSS. This does not interfere with any browser caching.
   */
  styleHost?: AssetHost;

  /**
   *  toggleEntity is launchdarkly user object for requesting flag version
   */
  toggleEntity?: VersionEntityDefinition;

  /**
   *  Boolean value to check whether to use LaunchDarkly or not
   */
  useLaunchDarkly?: boolean;
}

// Helper function to use a set timeout with await
function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Loads assets for a bundle and returns the contents of the primary asset
 * (usually a JS module).
 *
 * When called, will fetch the bundle's meta data, enumerate all the assets
 * and dependencies, and immediately load them
 *
 * When all assets have finished loading, the entry point module as defined
 * in the Meta file will be loaded using a call to the AMD require function.
 * The promise returned by this function will be resolved with the result of
 * that require call.
 *
 * @param bundle  Then bundle definition
 * @returns A promise which will resolve to the bundle's entry point.
 */
export function load<M = unknown>(options: LoadOptions | string): Promise<M> {
  const { styleHost, toggleEntity, useLaunchDarkly, ...bundle } = typeof options === 'string'
    ? parseBundleModuleName(options) as LoadOptions
    : options;
  return loadPrimaryAsset(bundle, { styleHost, toggleEntity, useLaunchDarkly }) as Promise<M>;
}

/**
 * Loads all assets from a bundle.
 *
 * When called, will fetch the bundle's meta data, enumerate all the assets
 * and dependencies, and immediately load them. When all assets have finished
 * loading, the promise returned will resolve.
 *
 * The promise returned by this function does not resolve to any meaningful
 * value. If you want it to resolve to the main module, use load..
 *
 * @export
 * @param bundle The bundle definition
 * @returns A promise which resolves when all assets have been loaded.
 */
export function preload(options: LoadOptions): Promise<void> {
  const { styleHost, ...bundle } = typeof options === 'string'
    ? parseBundleModuleName(options) as LoadOptions
    : options;

  return loadAssets(bundle, { styleHost });
}

/**
 * Prefetches assets for a module, but does not load them.
 *
 * Loads the bundle's meta data and enumerates all assets. Assets are marked
 * to be prefetched by the browser in idle time using a <link rel='prefetch' />
 * element.
 *
 * Items loaded this way will be downloaded by the browser when the browser
 * is idle (allowing user interaction to take priority) but will not evaluate
 * them. A later call to loadAssets will be able to skip downloading the meta
 * data and the assets.
 *
 * If you need to be sure that the bundle is _loaded_, you should still
 * call load or preload. Prefetching is primarily useful because it is
 * safe to put a prefetch as a synchronous dependency of another module,
 * but it is unsafe to do the same with load.
 *
 * @export
 * @param bundle The bundle definition
 * @returns A promise that resolves when all prefetch elements have been
 *  added. Assets will not have been loaded when this resolves.
 */
export async function prefetch(bundle: BundleDefinition | string): Promise<void> {
  // This could be running while Require is still resolving
  // modules, so sleep for a little bit before starting DOM
  // manipulation.
  await delay(10);
  bundle = typeof bundle === 'string'
    ? parseBundleModuleName(bundle)
    : bundle;
  await prefetchAssets(bundle);
}
