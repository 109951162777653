import { createCache } from './cache';
import { DEFAULT_CDN_NAME, FALLBACK_CDN_NAME, META_ELEMENT_ID_PREFIX, CACHE_BUST_QUERY_PARAM, BACKUP_META_ELEMENT_ID_PREFIX } from './constants';
import { loadMetaTags, getSettingMetaTagValue } from './dom-helpers';
import { BundleDefinition, BundleLocation, BundleLocationWithFallback } from '@athena/cdn-types';
import { createBundleName, createPartialBundleName } from './name-helpers';
import { normalizePackageName } from '@athena/nimbus-core/dist/src/names';

/**
 * <meta id="meta-cdn-conf:_default" content="cdn.athena.io" />
 * <meta id="meta-cdn-conf:inpatient" content="inpatient.cdn.athena.io" />
 * <meta
 *      id="meta-cdn-conf:inpatient:nursing_flowsheets"
 *      content="https://inpatient.cdn.athena.io/packages/flowsheets/"
 * />
 * <meta
 *      id="meta-cdn-conf:inpatient:discharge@2.0.0"
 *      content="https://inpatient.cdn.athena.io/packages/discharge/meta/2.0.1.meta.json"
 * />
 *
 *  <meta
 *      id="meta-cdn-conf:inpatient:nursing-tasks@DEV"
 *      content="localhost:8080/meta.json"
 * />
 */

/**
 * Cache to hold the configuration data from the meta tags.
 */
export const ConfigCache = createCache<string, string>('Config', (x): string => x, (prime) => {
  const metaConfig = loadMetaTags(META_ELEMENT_ID_PREFIX, true, BACKUP_META_ELEMENT_ID_PREFIX);
  for (const name in metaConfig) {
    prime(name, metaConfig[name]);
  }
});

export const URLCache = createCache<BundleDefinition, BundleLocation>("CDN URLs", createBundleName);

/**
 * Given a bundle definition, generate partials of that definition
 * that represent the potential configurations that will match the
 * bundle. Candidates are returned in decreasing order of
 * specificity because the first match will win.
 *
 * @param bundle
 * @returns An array of partial bundle definitions that should be
 *  checked for configuration values.
 */
export function configurationCandidates(
  bundle: BundleDefinition
): Partial<BundleDefinition>[] {
  return [
    bundle,
    { cdnName: bundle.cdnName, packageName: bundle.packageName },
    { cdnName: bundle.cdnName },
    {},
  ];
}

/**
 * Builds the URLs for a bundle based on the configuration data.
 *
 * This requires knowing what the configuration URL is (baseURL)
 * as well as why the rule matched (matched). Based on how specific
 * the match was, the base url is treated differently.
 *
 * @param bundle The bundle definition
 * @param matched The parts of the bundle definition that matched against
 *      the rule.
 * @param baseURL The URL from the configuration for the matched rule.
 * @returns The URLS for the bundle
 */
export function buildURL(
  bundle: BundleDefinition,
  matched: Partial<BundleDefinition>,
  baseURL: string
): BundleLocation {

  const cacheBustVersion = getSettingMetaTagValue('cache-bust');
  const cacheBustQueryString = cacheBustVersion
    ? `?${CACHE_BUST_QUERY_PARAM}=${cacheBustVersion}`
    : "";

  // only if we matched the full version number does the url resolve to a file. Otherwise, it
  // is a directory. To make string manipulations easier below, we make sure the last character
  // is a slash.
  if (!matched.version && baseURL[baseURL.length - 1] !== '/') {
    baseURL += '/';
  }

  let metaFileURL = baseURL;
  let relativeRoot = baseURL;

  if (matched.version) {
    // If there is a matched version, the last path segment
    // is the meta file name, and every thing else is the
    // relative root.
    // Example:
    //  nimbus:test@1.2.3 => https://cdn.nimbus.athena.io/some-non-standard/url/some-meta-file.json
    //  meta file url => https://cdn.nimbus.athena.io/some-non-standard/url/some-meta-file.json
    //  relative root => https://cdn.nimbus.athena.io/some-non-standard/url/
    const pathParts = relativeRoot.split('/');
    relativeRoot = pathParts.slice(0, pathParts.length - 1).join('/');
    metaFileURL += cacheBustQueryString;
  } else if (matched.packageName) {
    // Otherwise, if we matched a package name, then we assume we have
    // URL that points to the package's root directory, containing
    // a meta directory and one directory for each version.
    // Therefore if we want to build a URL for a specific version,
    // we must append that version number to the relative root
    // Example:
    //  nimbus:test => https://cdn.nimbus.athena.io/some-non-standard/url
    //  meta file url => https://cdn.nimbus.athena.io/some-non-standard/url/meta/{version}.meta.json
    //  relative root => https://cdn.nimbus.athena.io/some-non-standard/url/{version}
    relativeRoot += bundle.version
    metaFileURL += `meta/${bundle.version}.meta.json${cacheBustQueryString}`;
  } else {
    // Lastly, if we matched nothing else, we assume the url points to
    // a path that contains one folder per package.
    // Example:
    //  nimbus => https://cdn.nimbus.athena.io/some-non-standard/url
    //  meta file url => https://cdn.nimbus.athena.io/some-non-standard/url/{name}/meta/{version}.meta.json
    //  relative root => https://cdn.nimbus.athena.io/some-non-standard/url/{name}/{version}
    const packageName = normalizePackageName(bundle.packageName);
    relativeRoot += `${packageName}/${bundle.version}`;
    metaFileURL += `${packageName}/meta/${bundle.version}.meta.json${cacheBustQueryString}`;
  }

  return {
    metaFileURL: metaFileURL,
    relativeRoot: relativeRoot,
    cacheBust: cacheBustQueryString
  }
}

/**
 * Gets the configured location for a bundle.
 *
 * URLs are configured via 'meta' tags. The best match will be used
 * to build the URL to the meta file as well as the root directory
 * for all relative paths.
 *
 * @param bundle The bundle definition
 * @returns {BundleLocation} The URLs for the bundle
 * @throws When no configuration matches.
 */
export function getLocation(bundle: BundleDefinition): BundleLocationWithFallback {
  // Cache all built URLs
  return URLCache.getDefault(bundle, (): BundleLocationWithFallback => {
    // Determine the fallback location, if available
    const fallback = ConfigCache.has(FALLBACK_CDN_NAME)
      ? buildURL(bundle, {}, ConfigCache.get(FALLBACK_CDN_NAME)!)
      : undefined;
    // Try each candidate in turn
    const candidates = configurationCandidates(bundle);
    for (const candidate of candidates) {
      // Generate the partial name for the candidate. This will be
      // what the candidate would be configured as.
      const key = createPartialBundleName(candidate) || DEFAULT_CDN_NAME;

      // Build and return the url if this candidate has been
      // configured
      if (ConfigCache.has(key)) {
        return {
          ...buildURL(bundle, candidate, ConfigCache.get(key)!),
          fallback,
        };
      }
    }

    // If there is no match, throw an error. Currently this should
    // only happen if no default url was specified.
    throw new Error(`Could not build url for: ${createBundleName(bundle)}`);
  });
}

export type SettingName =
  | "cache-bust"
  | "use-cross-origin-attr";

/**
 * Cache to store other settings
 * TODO: We should maybe consolidate these caches
 */
export const SettingCache = createCache<SettingName, string | null>("Setting", (x: string) => x);

export function getSetting(name: SettingName): string | null {
  return SettingCache.getDefault(name, () => {
    return getSettingMetaTagValue(name);
  });
}
