import cookies from 'browser-cookies';

// TODO: These two constants should be determined by athenaconf
export const NUMBER_OF_ERRORS_BEFORE_FALLBACK_AS_DEFAULT = 5;
export const NUMBER_OF_MILLISECONDS_TO_PERSIST_COOKIE =
       1 /* hour */
  *   60 /* min/hour */
  *   60 /* sec/min */
  * 1000 /* ms/sec */;

export const ERROR_COUNT_COOKIE_NAME = 'NIMBUS_ERROR_COUNT';

/**
 * Determines the number of times a nimbus app has failed to load
 * during the current session.
 * @returns the count of errors seen
 */
function getCurrentErrorCount(): number {
  return parseInt(cookies.get(ERROR_COUNT_COOKIE_NAME) || '0');
}

/**
 * Increments the number of times a nimbus app has failed to load
 * during the current session.
 * @returns undefined
 */
export function incrementErrorCountCookie(): void {
  cookies.set(ERROR_COUNT_COOKIE_NAME, `${getCurrentErrorCount() + 1}`, {
    expires: new Date(Date.now() + NUMBER_OF_MILLISECONDS_TO_PERSIST_COOKIE),
  });
}

/**
 * Determines if we should skip the attempt to load from the default CDN
 * and fall back immediately.
 * @returns Whether or not to skip the default CDN
 */
export function shouldUseFallbackAsDefault(): boolean {
  return  getCurrentErrorCount() >= NUMBER_OF_ERRORS_BEFORE_FALLBACK_AS_DEFAULT;
}
