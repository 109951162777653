import { Cache, createCache } from "./cache";
import { BundleDefinition } from "@athena/cdn-types";
import { addGlobalDataCacheToWindow } from "./dom-helpers";

export interface BundleData {
  publicPath?: string;
  cssNamespace?: string;
  useFallback?: boolean;
}

export interface GlobalData {

  /**
   * Get a piece of global data for the given package.
   * 
   * This variant is provided to avoid creating an object when calling
   * it from a bundle.
   * 
   * @param packageName The name of the package
   * @param version  The version of the package
   * @param key A key of the this BundleData interface, the property to get
   * @returns The value of the key for the package
   */
  get<K extends keyof BundleData>(packageName: string, version: string, key: K): BundleData[K];

  /**
   * Get a piece of global data for the given package.
   * 
   * @template K 
   * @param bundle The definition of the bundle
   * @param key A key of the this BundleData interface, the property to get
   * @returns The value of the key for the package
   */
  get<K extends keyof BundleData>(bundle: BundleDefinition, key: K): BundleData[K];

  /**
   * Sets a piece of global data for the given package.
   * 
   * @template K 
   * @param bundle The definition of the bundle
   * @param key A key of the this BundleData interface, the property to set
   * @param value The value to set in the global data cache.
   */
  set<K extends keyof BundleData>(bundle: BundleDefinition, key: K, value: BundleData[K]): void;
}

interface CacheKey {
  packageName: string;
  version?: string;
}

function hashCacheKey(key: CacheKey): string {
  return `${key.packageName}@${key.version}`;
}

class GlobalDataCache implements GlobalData {

  private cache: Cache<CacheKey, BundleData>;

  private static instance?: GlobalDataCache;

  /**
   * Get the singleton instance of the cache
   */
  public static getInstance(): GlobalDataCache {
    if (!this.instance) {
      this.instance = new this()
      // Add the instance to the window object.
      addGlobalDataCacheToWindow({
        get: this.instance.get.bind(this.instance),
      });
    }
    return this.instance;
  }

  private constructor() {
    this.cache = createCache("Global", hashCacheKey);
  }

  private getData(key: CacheKey): BundleData {
    const data = this.cache.getDefault(key, () => ({}));
    return data;
  }

  public get<K extends keyof BundleData>(packageName: string, version: string, key: K): BundleData[K];
  public get<K extends keyof BundleData>(bundle: BundleDefinition, key: K): BundleData[K];
  public get<K extends keyof BundleData>(...args: [BundleDefinition, K] | [string, string, K]): BundleData[K] {
    if (args.length === 2) {
      const { packageName, version } = args[0];
      return this.getData({ packageName, version })[args[1]];
    }
    else {
      return this.getData({
        packageName: args[0],
        version: args[1],
      })[args[2]]
    }
  }

  public set<K extends keyof BundleData>(bundle: BundleDefinition, key: K, value: BundleData[K]): void {

    // Webpack requires public paths to have a trailing /, so
    // quickly make sure thats true.
    if (key === 'publicPath') {
      const path = value as string;
      value = (path[path.length - 1] === '/' ? path : path + '/') as BundleData[K];
    }

    this.getData(bundle)[key] = value;
  }
}

/**
 * Get the the global data cache. This cache is used by the
 * bundle loader to store data about each package it has
 * resolved.
 * 
 * This is used to pass runtime data to each bundle.
 * 
 * Data exposed this way should be considered part of the
 * bundle loaders API, changing or removing data here is
 * a breaking change.
 * 
 * @returns The global data instance
 */
export function getGlobalData(): GlobalData {
  return GlobalDataCache.getInstance();
}

/**
 * Get a piece of global data for the given package.
 * 
 * @template K 
 * @param bundle The definition of the bundle
 * @param key A key of the this BundleData interface, the property to get
 * @returns The value of the key for the package
 */
export function getGlobalDatum<K extends keyof BundleData>(
  bundle: BundleDefinition,
  key: K
): BundleData[K] {
  return getGlobalData().get(bundle, key);
}

/**
 * Sets a piece of global data for the given package.
 * 
 * @template K 
 * @param bundle The definition of the bundle
 * @param key A key of the this BundleData interface, the property to set
 * @param value The value to set in the global data cache.
 */
export function setGlobalDatum<K extends keyof BundleData>(
  bundle: BundleDefinition,
  key: K,
  value: BundleData[K]
): void {
  getGlobalData().set(bundle, key, value);
}
