import * as BundleLoader from './bundle-loader';
import { parseBundleModuleName } from './name-helpers';

/**
 * Type for an AMD plugin load function.
 */
export interface BundleLoadFunction {
  (
    bundleName: string,
    parentRequire: Require,
    onLoad: AthenaRequire.PluginOnLoad
  ): void;
}

/**
 * Creates an AMD plugin load function.
 *
 * e.g. to create an AMD plugin that uses the 'prefetch' method of the BundleLoader.
 *
 *  // cdn-prefetch-plugin.js
 *  define('@cdn-prefetch', function() {
 *      return {
 *          load: createPlugin('prefetch')
 *      };
 *  });
 *
 *  // some-consumer.js
 *  require('@cdn-prefetch!xxxx');
 *
 * @param methodName The method name to wrap in a plugin.
 */
export function createPlugin<M extends 'load' | 'prefetch' | 'preload'>(methodName: M): BundleLoadFunction {
  return function load(
    bundleName: string,
    _parentRequire: Require,
    onLoad: AthenaRequire.PluginOnLoad
  ): void {
    const parsed = parseBundleModuleName(bundleName);
    BundleLoader[methodName](parsed).then(onLoad);
  };
}