import { ON_LOAD_CALLBACK_DELAY, GLOBAL_OBJECT_NAMESPACE, META_SETTING_PREFIX, BACKUP_META_SETTING_PREFIX } from './constants';

// A CDN name is usually separated by a ':' from the rest of the bundle name,
// but for printing's sake, it can also use '__'. This isolates just that '__'.
const CDN_NAME_SEPARATOR_REGEX = /(?![a-z]+(?:\-[a-z]+)*)(?:__)/;

/**
 * Interface describing an element that can be used as a host for assets. The
 * only requirement is that we can append elements to it. We create an interface
 * here because ShadowRoots can be used as an asset host, but are not
 * instances of HTMLElement.
 */
export interface AssetHost {
  appendChild(node: HTMLElement): void;
}

/**
 * Load configuration data from the page's meta tags.
 *
 * Checks for meta tags with a given prefix on their name attributes.
 * Removes that prefix and returns an object mapping name -> content.
 *
 * @export
 * @param prefix A string prefix used to filter meta tags.
 * @param [throwOnDuplicate] If true, will throw an error
 *      if the same tag is found twice in the DOM. Otherwise the
 *      latest tag will win.
 * @param [backupPrefix] An alternate string prefix for filtering
 *      meta tags, temporary as we move to a new meta tag format.
 * @returns {{ [name: string]: string }}
 */
export function loadMetaTags(
  prefix: string,
  throwOnDuplicate?: boolean,
  backupPrefix?: string
): { [name: string]: string } {
  const result: { [name: string]: string } = {};
  const metaTags = document.getElementsByTagName('meta');

  for (let i = 0; i < metaTags.length; i++) {
    const element = metaTags[i];
    let id = element.id || element.name;

    if (
      !stringStartsWith(id, prefix)
      && !(backupPrefix && stringStartsWith(id, backupPrefix))
    ) {
      continue;
    }

    id = id.replace(prefix, '');
    if (backupPrefix) {
      id = id.replace(backupPrefix, '');
      id = id.replace(CDN_NAME_SEPARATOR_REGEX, ':');
    }

    const content = element.content.trim();
    if (result[id] && throwOnDuplicate && result[id] !== content) {
      throw new Error('Duplicate meta tag encountered: ' + id);
    }

    result[id] = content;
  }
  return result;
}

// IE does not implement String.prototype.startsWith.
export function stringStartsWith(haystack: string, needle: string): boolean {
  return haystack.substr(0, needle.length) === needle;
}



// Helper to attach an onload or onerror event to an element
interface OnLoadCallback {
  (): void;
}
interface OnErrorCallback {
  (event: ErrorEvent): void;
}
function attachElementHandler(
  element: HTMLElement,
  event: 'onload' | 'onerror',
  callback: OnLoadCallback | OnErrorCallback,
): void {
  element[event] = (evt: Event | string) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    element.onload = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    element.onerror = () => {};
    setTimeout(()=>callback(evt as ErrorEvent), ON_LOAD_CALLBACK_DELAY);
  };
}

/**
 * Attach a prefetch link to the head of the document for
 * the given url.
 *
 * @param href The URL of the resource to prefetch
 */
export function attachPrefetch(href: string): void {
  const link = document.createElement('link');
  link.rel = 'prefetch';
  link.href = href;
  document.head.append(link);
}

/**
 * Attach a CSS file to the DOM
 *
 * @param host The element to add the CSS file to.
 * @param href The full url of the CSS file
 * @param addCrossOrigin When true, add the crossorgin='anonymous' attribute
 *                       to the node, which helps with CORS in some cases.
 * @returns A promise that is resolved when the file is
 *      loaded or rejected if there was an error.
 */
export function attachCSS(host: AssetHost, href: string, addCrossOrigin: boolean): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    if (addCrossOrigin) {
      link.crossOrigin = "anonymous";
    }
    attachElementHandler(link, 'onload', resolve);
    attachElementHandler(link, 'onerror', (event: ErrorEvent) => {
      const message = event && event.message ? event.message : 'Unknown error';
      reject(new Error(`Failed to attach CSS resource ${href}: ${message}`));
    });
    link.href = href;
    host.appendChild(link);
  });
}

/**
 * Attach a JS file to the DOM
 *
 * @param host The element to add the JS file to.
 * @param href The full url of the JS file
 * @param addCrossOrigin When true, add the crossorgin='anonymous' attribute
 *                       to the node, which helps with CORS in some cases.
 * @returns A promise that is resolved when the file is
 *      loaded or rejected if there was an error.
 */
export function attachJS(host: AssetHost, src: string, addCrossOrigin: boolean): Promise<void> {
  // TODO: I think we need to inspect the script ready state in some
  // capacity for IE compatibility.
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    if (addCrossOrigin) {
      script.crossOrigin = "anonymous";
    }
    attachElementHandler(script, 'onload', resolve);
    attachElementHandler(script, 'onerror', (event: ErrorEvent) => {
      const message = event && event.message ? event.message : 'Unknown error';
      reject(new Error(`Failed to attach JS resource ${src}: ${message}`));
    });
    script.src = src;
    host.appendChild(script);
  });
}

/**
 * Sets the global data cache object on window. This makes it
 * accessible to bundles at runtime.
 *
 * @param cache The GlobalData cache object
 * @throws When there is already a cache object on the window, and
 *  it is a different object than the one provided.
 */
export function addGlobalDataCacheToWindow(cache: unknown): void {
  // Prevents the need to always mock this function in tests.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (window == null) {
    return;
  }

  // Cast to any to arbitrary key assignment.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalObject: any = window;

  // Throw an error if there is already an object assigned to
  // the global namespace, unless it's the same object.
  if (
    typeof globalObject[GLOBAL_OBJECT_NAMESPACE] !== 'undefined'
    && globalObject[GLOBAL_OBJECT_NAMESPACE] !== cache)
  {
    throw new Error('Cannot change the global cache object');
  }

  globalObject[GLOBAL_OBJECT_NAMESPACE] = cache;
}

export function removeGlobalCacheFromWindow(): void {
  const globalObject = window || {};
  delete globalObject[GLOBAL_OBJECT_NAMESPACE as never];
}

/**
 * Gets the cache bust string from the meta tags.
 */
export function getSettingMetaTagValue(name: string): string | null {
  const selector = `meta[name="${META_SETTING_PREFIX}${name}"]`; //"meta[name='meta-cdn-setting:${name}']"
  const backupSelector = `meta[name="${BACKUP_META_SETTING_PREFIX}${name}"]`;//"meta[name='meta-cdn-setting-${name}']"
  const element: HTMLMetaElement | null = document.querySelector(selector)
    || document.querySelector(backupSelector);
  return element && element.content;
}

export function preventCSSInheritance(host: AssetHost): void {
  const style = document.createElement('style');
  const textNode = document.createTextNode(
    `:host {
      all: initial;
      contain: content;
    }`
  );
  style.appendChild(textNode);
  host.appendChild(style);
}

export function isInShadowDOM(element: Node): boolean {
  do {
    if (element instanceof ShadowRoot) {
      return true;
    }
  } while (element.parentNode && (element = element.parentNode));

  return false;
}