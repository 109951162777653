

/**
 * Callback that lazy initializes a cache.
 *
 * @template K
 * @template V
 */
interface LazyDefault<K, V> {
  (key: K, stringKey: string): V;
}

/**
 * A cache object which handles the hashing of object keys.
 *
 * Cannot store undefined values.
 *
 * @template K The type of keys
 * @template V The type of values
 */
export interface Cache<K, V> {
  /**
     * Sets a value in the cache
     *
     * @param key
     * @param value
     */
  set(key: K, value: V): void;

  /**
    * Resets the cache
    */
  reset(): void;

  /**
     * Gets an item from the cache, or undefined.
     *
     * @param key
     * @returns
     */
  get(key: K): V | undefined;

  /**
     * Checks if a key is present in the cache.
     *
     * @param key
     * @returns
     */
  has(key: K): boolean;

  /**
     * Initializes the cache with a default value from a
     * function if the value has not been set in the cache.
     * returns the value.
     *
     * @param key
     * @param lazyDefault
     * @returns
     *
     * @memberOf Cache
     */
  getDefault(key: K, lazyDefault: LazyDefault<K, V>): V;
}

// Internal interface to store cache data
interface RawCache<K, V> {
  /**
     * The name of the cache
     */
  name: string;

  /**
     * The function to hash keys to strings
     */
  key: HashFunction<K>;

  /**
     * The actual value cache
     */
  cache: { [key: string]: V };

  /**
     * Boolean flag indicating that setting the same key
     * twice should throw an error.
     */
  throwOnReplace: boolean;

  /**
   * Boolean indicating that the cache has been primed.
   */
  isPrimed?: boolean;

  /**
   * Function to call to prime the cache.
   */
  prime?: (set: Cache<K, V>['set']) => void;
}

interface HashFunction<T> {
  (value: T): string;
}

// A global object to store caches. A cache of caches.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalCache: RawCache<string, RawCache<any, any>> = {
  name: 'GlobalCache',
  key: (key: string) => key,
  cache: {},
  throwOnReplace: true,
}

/**
 * Create a new cache.
 *
 * @template K The key type
 * @template V The value type
 * @param name The name of the cache. Must be unique.
 * @param getKey A hash function which maps K to a string.
 * @param prime a function that is called before the cache is first accessed
 *              that can be used to prime the cache. Receives a single parameter
 *              that is a setter function for the cache.
 * @returns The created cache.
 */
export function createCache<K, V>(
  name: string,
  getKey: HashFunction<K>,
  prime?: (set: Cache<K, V>['set']) => void,
): Cache<K, V> {
  const raw: RawCache<K, V> = {
    name: name,
    key: getKey,
    cache: {},
    throwOnReplace: !!false,
    isPrimed: false,
    prime: prime,
  }

  set(GlobalCache, name, raw);

  return {
    get: (key) => get(raw, key),
    set: (key, value) => set(raw, key, value),
    reset: () => reset(raw),
    has: (key) => has(raw, key),
    getDefault: (key, factory) => getDefault(raw, key, factory),
  }
}

function prime<K, V>(cache: RawCache<K, V>): void {
  if (cache.prime && !cache.isPrimed) {
    cache.isPrimed = true;
    cache.prime((key, value) => set(cache, key, value),)
  }
}

/**
 * Test helper used to reset all caches.
 */
export function resetAllCaches(): void {
  for (const name in GlobalCache.cache) {
    reset(GlobalCache.cache[name])
  }
}

function reset<K, V>(
  cache: RawCache<K, V>
): void {
  cache.cache = {};
  cache.isPrimed = false;
}

function has<K, V>(
  cache: RawCache<K, V>,
  key: K,
): boolean {
  prime(cache);
  const stringKey = cache.key(key);
  return typeof cache.cache[stringKey] !== 'undefined';
}

function set<K, V>(
  cache: RawCache<K, V>,
  key: K,
  value: V,
): void {
  prime(cache);
  const stringKey = cache.key(key);
  if (cache.throwOnReplace && typeof cache.cache[stringKey] !== 'undefined') {
    throw new Error(`The key '${stringKey} already exists in ${cache.name}.`);
  }
  cache.cache[stringKey] = value;
}

function get<K, V>(
  cache: RawCache<K, V>,
  key: K,
): V {
  prime(cache);
  const stringKey = cache.key(key);
  return cache.cache[stringKey];
}

function getDefault<K, V>(
  cache: RawCache<K, V>,
  key: K,
  lazyDefault: (key: K, stringKey: string) => V
): V {
  prime(cache);
  // avoid calling other methods to avoid re-hashing the key
  const stringKey = cache.key(key);
  if (typeof cache.cache[stringKey] === 'undefined') {
    cache.cache[stringKey] = lazyDefault(key, stringKey);
  }
  return cache.cache[stringKey];
}
