import { createCache } from "./cache";
import { getLocation, getSetting } from "./configuration";
import { attachCSS, attachJS, attachPrefetch, AssetHost, preventCSSInheritance, isInShadowDOM } from "./dom-helpers";
import { AssetFileType, BundleDefinition, BundleLocation, BundleModule, Dict, VersionEntityDefinition } from "@athena/cdn-types";
import { getMeta, NimbusMetaFile } from "./meta";
import { getAssetId } from "./name-helpers";
import { shouldUseFallbackAsDefault } from "./cookies";
import { getGlobalData } from "./global-data";
import { getApplicationVersion } from "./version";

/**
 * Helper interface for an asset that may or may not have been loaded.
 */
interface Asset {

  /**
     *  A unique ID for asset.
     */
  assetId: string;

  /**
     * The file type of the asset. Needed to know how to attach
     * the file to the DOM.
     */
  type: AssetFileType;

  /**
     * A url where this can be found. Note that the ModuleName
     * cannot be used to determine the URL, as the same module
     * could live at many URLS
     */
  url: string;

  /**
   * A fallback URL where this can be found if the default URL fails to load
   */
  fallbackURL?: string;
}

/**
 * The status of an asset. Keeps track if its been loaded or prefetched.
 */
interface AssetStatus {

  /**
     * A reference to the asset itself.
     */
  asset: Asset;

  /**
     * A promise that will resolve when the asset has has been
     * successfully attached to the DOM. Will be missing if
     * loading has not started yet. Note that this promise
     * may resolve before the JS module in the asset can be
     * imported, as there may be other dependencies left to
     * download.
     */
  loadPromise?: Promise<void>;

  /**
     * Boolean flag indicating that this asset has been prefetched
     */
  prefetched: boolean;
}

/** Options used when attaching the assets */
interface AssetOptions {

  /**
   * The HTML element to which CSS tags will be added as children.
   * **Note:** Providing this option disables the asset cache for CSS.
   */
  styleHost?: AssetHost;

  /**
   *  toggleEntity is launchdarkly user object for requesting flag version
   */
  toggleEntity?: VersionEntityDefinition;

  /**
   *  Boolean value to check whether to use LaunchDarkly or not
   */
  useLaunchDarkly?: boolean;
}

/**
 * Cache to store the asset status. Keyed by module name, allowing
 * the same module to live on multiple CDNs and only be downloaded
 * once.
 */
const AssetCache = createCache<Asset, AssetStatus>('Assets', a => a.assetId)

/**
 * Helper to initialize and get an asset from the cache.
 *
 * @param asset
 * @returns A new asset status, or the existing one from the cache
 */
function getAssetStatus(asset: Asset): AssetStatus {
  return AssetCache.getDefault(asset, () => ({
    asset,
    loadPromise: undefined,
    prefetched: false,
  }));
}

/**
 * Prefetches an asset if that asset has not already been loaded or
 * prefetched. Does not return a promise because we can't know when
 * the browser has finished prefetching.
 *
 * @param asset
 */
function prefetchAsset(asset: Asset): void {
  const assetStatus = getAssetStatus(asset);

  if (assetStatus.prefetched || assetStatus.loadPromise) {
    return;
  }

  assetStatus.prefetched = true;
  attachPrefetch(asset.url)
}

/**
 * Attach an asset to the DOM. Returns a promise that will
 * resolve when the asset's onload handler fires. Throws
 * an error for unsupported assets.
 *
 * @param asset
 * @param useFallback If true, use the fallback URL instead of the default
 * @returns A promise resolving when the asset has loaded.
 */
async function attachAsset(asset: Asset, useFallback: boolean, options?: AssetOptions): Promise<void> {

  try {
    const addCrossOrigin = !!getSetting('use-cross-origin-attr');

    const url = (asset.fallbackURL && useFallback)
      ? asset.fallbackURL
      : asset.url;

    switch (asset.type) {
      case 'js':
        const jsHost = document.body;
        return await attachJS(jsHost, url, addCrossOrigin);

      case 'css':
        const styleHost = options && options.styleHost || document.head
        return await attachCSS(styleHost, url, addCrossOrigin);

      default:
        throw new Error('Could not attach asset type ' + asset.type);
    }
  }
  catch (error) {
    // If we caught an error, and haven not tried an available fallback yet,
    // attempt to use the fallback before allowing the error to propagate.
    if (!useFallback && asset.fallbackURL) {
      return attachAsset(asset, true, options);
    }
    throw error;
  }
}

/**
 * Loads an asset. Checks the assets status to make sure that
 * the asset has not already been attached to the DOM and is
 * currently loading. Returns a promise tha resolves when the
 * asset is available.
 *
 * @param asset
 * @param options Additional options for how to load assets
 * @returns A promise when the asset has loaded
 */
async function loadAsset(asset: Asset, options: AssetOptions): Promise<void> {
  const assetStatus = getAssetStatus(asset);
  const useFallback = shouldUseFallbackAsDefault();

  // Don't use the asset cache for CSS when styleHost is given. Otherwise,
  // shadow doms after the first render for the same app won't get the CSS.
  if (options.styleHost && asset.type === 'css') {
    return attachAsset(asset, useFallback, options);
  }
  else if (!assetStatus.loadPromise) {
    assetStatus.loadPromise = attachAsset(asset, useFallback, options);
  }

  return assetStatus.loadPromise;
}

/**
 * Helper function to generate the url of an asset from its relative
 * root and it's filename.
 *
 * @param location The urls of the bundle
 * @param filename The relative file name of the asset
 * @returns An absolute URL to the file.
 */
function buildURL(location: BundleLocation, filename: string): string {
  // TODO: probably should support relative paths with ..
  // TODO: Also probably need to check existing query params on url
  return `${location.relativeRoot}/${filename}${location.cacheBust}`;
}

/**
 * Recursively fetches all of the assets required for dependencies
 * @param cdnName
 * @param meta
 * @param useFallback
 * @returns A promise that resolves to an array of all of the assets required for the dependencies of a bundle
 */
async function getDependencyAssets(cdnName: string, meta: NimbusMetaFile, useFallback?: boolean, options?: AssetOptions): Promise<Asset[]> {
  const assets: Asset[] = [];
  // Loop over dependencies and extract each of their assets.
  for (const dependencyName in meta.dependencies) {
    const dependency = meta.dependencies[dependencyName];

    // Load the URLs for the dependency package. Because this
    // is cached in getURL, there is no need to do this outside
    // the loop.
    const dependencyLocation = getLocation({
      cdnName,
      packageName: dependencyName,
      version: dependency.version,
    });

    dependency.assets = filterShadowAssets(dependency.assets, options);

    // Push each asset onto the results array.
    for (const asset of dependency.assets) {
      assets.push({
        type: asset.type,
        url: useFallback
          ? buildURL(dependencyLocation.fallback!, asset.filename)
          : buildURL(dependencyLocation, asset.filename),
        fallbackURL: dependencyLocation.fallback
          ? buildURL(dependencyLocation.fallback, asset.filename)
          : undefined,
        assetId: getAssetId({
          assetName: asset.filename,
          packageName: dependencyName,
          packageVersion: dependency.version,
        })
      });
    }

    // Push the dependencies of package dependency
    if (dependency.dependencies) {
      const dependencyObj: any = dependency.dependencies;
      if (Object.keys(dependencyObj).length > 0) {
        for (const levelDependencyName in dependency.dependencies) {
          const levelDependency = dependency.dependencies[levelDependencyName];
          // Load the URLs for the level dependency package. Because this
          // is cached in getURL, there is no need to do this outside
          // the loop.
          const levelDependencyLocation = getLocation({
            cdnName,
            packageName: levelDependencyName,
            version: levelDependency.version,
          });

          // Push each asset onto the results array.
          for (const levelAsset of levelDependency.assets) {
            assets.push({
              type: levelAsset.type,
              url: useFallback
                ? buildURL(levelDependencyLocation.fallback!, levelAsset.filename)
                : buildURL(levelDependencyLocation, levelAsset.filename),
              fallbackURL: levelDependencyLocation.fallback
                ? buildURL(levelDependencyLocation.fallback, levelAsset.filename)
                : undefined,
              assetId: getAssetId({
                assetName: levelAsset.filename,
                packageName: levelDependencyName,
                packageVersion: levelDependency.version,
              })
            });
          }
        }
      }
    }

  }

  return assets;
}

/**
 * Gets an array of all assets that are needed for a given bundle,
 * including dependencies. The assets are in no particular order,
 * and therefore it should be assumed that all assets need to be
 * loaded and ready before any JS module that may be included will
 * work.
 *
 * @param bundle
 * @returns A promise that resolves to an array of all the assets
 *  needed by the bundle
 */
async function getBundleAssets(bundle: BundleDefinition, options?: AssetOptions): Promise<Asset[]> {
  const assets: Asset[] = [];

  const meta = await getMeta(bundle);
  const bundleLocation = getLocation({
    cdnName: bundle.cdnName,
    packageName: meta.name,
    version: meta.version
  });

  const globalData = getGlobalData();
  const useFallback = globalData.get(bundle, 'useFallback');
  meta.assets = filterShadowAssets(meta.assets, options);

  // Enumerate each asset defined in the current package.
  for (const asset of meta.assets) {
    assets.push({
      type: asset.type,
      url: useFallback
        ? buildURL(bundleLocation.fallback!, asset.filename)
        : buildURL(bundleLocation, asset.filename),
      fallbackURL: bundleLocation.fallback
        ? buildURL(bundleLocation.fallback, asset.filename)
        : undefined,
      assetId: getAssetId({
        assetName: asset.filename,
        packageName: bundle.packageName,
        packageVersion: meta.version,
      })
    });
  }

  assets.push(...(await getDependencyAssets(bundle.cdnName, meta, useFallback, options)));

  return assets;
}

/**
 * Filter css assets to return shadow assets if shadow host is present
 * Or remove shadow assets if shadow host is not present
 * 
 * @param assets Meta assets
 * @param options Additional options for how to load assets
 * @returns Filtered assets containing shadow assets if shadow host is present
 * Or remove shadow assets if shadow host is not present
 */
function filterShadowAssets(assets: NimbusMetaFile['assets'], options?: AssetOptions): NimbusMetaFile['assets'] {
  if (assets) {
    // Determine which assets have equivalent shadow assets
    const shadowAssets = assets.filter(
      asset => asset.type === 'css' && asset.filename.startsWith('shadow-')
    ).reduce((reduction, asset) => {
      reduction[asset.filename.replace(/^shadow-/, '')] = true;
      return reduction;
    }, {} as Record<string, boolean>);

    // Filter shadowAssets based on Shadow host
    if (options?.styleHost) {
      // Exclude CSS assets that have an equivalent shadow asset
      return assets.filter(asset => asset.type !== 'css' || !shadowAssets[asset.filename]);
    }
    else {
      // Exclude CSS shadow assets
      return assets.filter(asset => asset.type !== 'css' || !asset.filename.startsWith('shadow-'));
    }
  }
  return assets;
}

/**
 * Wrapper around the global require function that returns a promise rather
 * than use a callback.
 *
 * @param moduleName The name of the module to load
 * @returns A promise that resolves to the module, or rejects if an error is
 *      thrown during import.
 */
function requireModule(moduleName: string): Promise<BundleModule> {
  // When error callbacks are added to athena-require, we can
  // change this to just be `return import(entryName)`
  return new Promise((resolve, reject) => {
    try {
      window.require([moduleName], resolve);
    }
    catch (error: any) {
      reject(new Error(`Failed to require ${moduleName}: ${error.message}`));
    }
  });
}

/**
 * Helper method to require many modules all at once. Used to resolve
 * entry points of bundles which contain more than one module.
 *
 * @param modules A dictionary whose values are AMD module names.
 * @returns A promise that resolves to an object with the same keys, with
 *      the values as all modules loaded.
 */
async function requireModules(modules: Dict<string>): Promise<Dict<BundleModule>> {
  const promises = Object.entries(modules).map(async ([alias, name]) => {
    return [alias, await requireModule(name)] as [string, BundleModule];
  });

  const results = await Promise.all(promises);

  return results.reduce((hash, [alias, module]) => {
    hash[alias] = module;
    return hash;
  }, {} as Dict<BundleModule>);
}

/**
 * Loads all assets from a bundle.
 *
 * When called, will fetch the bundle's meta data, enumerate all the assets
 * and dependencies, and immediately load them. When all assets have finished
 * loading, the promise returned will resolve.
 *
 * The promise returned by this function does not resolve to any meaningful
 * value. If you want it to resolve to the main module, use loadPrimaryAsset.
 *
 * @param bundle The bundle definition
 * @param options Additional options for how to load assets
 * @returns  A promise which resolves when all assets have been loaded.
 */
export async function loadAssets(
  bundle: BundleDefinition,
  options: AssetOptions = {}
): Promise<void> {
  const assets = await getBundleAssets(bundle, options);
  if (options.styleHost && options.styleHost instanceof Node && isInShadowDOM(options.styleHost)) {
    preventCSSInheritance(options.styleHost);
  }
  const promises = assets.map(asset => loadAsset(asset, options));
  await Promise.all(promises);
}

/**
 * Helper method to log error.
 *
 * @param errorMsg error message to log.
 * @returns 
 */
export function logError(errorMsg: string): void {
  if (window["globalThis"] && (window as any)["globalThis"].AthenaNetError) {
    (window as any)["globalThis"].AthenaNetError(errorMsg);
  }
  else if (window["globalThis"] && window["globalThis"].top && (window as any)["globalThis"].top.AH) {
    (window as any)["globalThis"].top.AH.frame.AthenaNetError(errorMsg);
  }
  else {
    console.error(errorMsg);
  }
} 

/**
 * Loads assets for a bundle and returns the contents of the primary asset
 * (usually a JS module).
 *
 * When called, will fetch the bundle's meta data, enumerate all the assets
 * and dependencies, and immediately load them
 *
 * When all assets have finished loading, the entry point module as defined
 * in the Meta file will be loaded using a call to the AMD require function.
 * The promise returned by this function will be resolved with the result of
 * that require call.
 *
 * @param bundle  Then bundle definition
 * @param options Additional options for how to load assets
 * @returns A promise which will resolve to the bundle's entry point.
 */
export async function loadPrimaryAsset(
  bundle: BundleDefinition,
  options: AssetOptions = {}
): Promise<BundleModule | Dict<BundleModule>> {
  try {
    if (!bundle?.version && options?.useLaunchDarkly) {
      bundle.version = await getApplicationVersion(options, bundle)
    } else if (!bundle?.version && !options?.useLaunchDarkly) {
      throw ("Either version or useLaunchDarkly has to be passed to load the application");
    }
  } catch (err) {
    const toggleKey = options?.toggleEntity ? '-' + options.toggleEntity.key : '';
    const message_prefix = `NimbusBundleLoaderLDError: Error while fetching LaunchDarkly version for application ${bundle.packageName}-${bundle.version}${toggleKey}: `;
    const errorMsg = (err instanceof Error) ? message_prefix + err.message : message_prefix + err;
    logError(errorMsg);
    return;
  }

  await loadAssets(bundle, options);
  const meta = await getMeta(bundle);

  if (!meta.modules) {
    return;
  }
  else if (typeof meta.modules === 'string') {
    return requireModule(meta.modules);
  }
  else {
    return requireModules(meta.modules);
  }
}

/**
 * Prefetches assets for a module, but does not load them.
 *
 * Loads the bundle's meta data and enumerates all assets. Assets are marked
 * to be prefetched by the browser in idle time using a <link rel='prefetch' />
 * element.
 *
 * Items loaded this way will be downloaded by the browser when the browser
 * is idle (allowing user interaction to take priority) but will not evaluate
 * them. A later call to loadAssets will be able to skip downloading the meta
 * data and the assets.
 *
 * If you need to be sure that the bundle is _loaded_, you should still
 * call loadAssets or loadPrimaryAsset. Prefetching is primarily useful
 * because it is safe to put a prefetch as a synchronous dependency of
 * another module, but it is unsafe to do the same with loadAssets.
 *
 * @export
 * @param bundle
 * @returns
 */
export async function prefetchAssets(bundle: BundleDefinition): Promise<void> {
  const assets = await getBundleAssets(bundle);
  const promises = assets.map(asset => prefetchAsset(asset));
  await Promise.all(promises);
}
