import { BundleDefinition, AssetDefinition } from "@athena/cdn-types";

// CDN Names are lower case, separated by hyphens, don't
// start or end with a hyphen, with a colon at the end.
const CDN_NAME_REGEX = /([a-z]+(?:\-[a-z]+)*)(?::|__)/;

// Package names are lower case, separated by hyphens, and don't
// start or end with a hyphen.
const PACKAGE_NAME_REGEX = /([a-z][a-z0-9]*(?:\-[a-z0-9]+)*)/;

// Versions are either Semver X.Y.Z, or all capital letter tags,
// and start with an @.
// TODO:extended semver for things like RCs and Alphas
const VERSION_REGEX = /@((?:[0-9]+\.[0-9]+\.[0-9]+[a-zA-Z_0-9]*))/;

const BUNDLE_MODULE_NAME_REGEX = joinRegExp(
  CDN_NAME_REGEX,
  PACKAGE_NAME_REGEX,
  VERSION_REGEX
);

/**
 * Creates a bundle's string name from its structure.
 *
 * cdn-name:package-name@version
 *
 * @export
 * @param bundle The bundle definition
 * @returns {string} The name of the bundle
 */
export function createBundleName(bundle: BundleDefinition): string {
  return `${bundle.cdnName}:${bundle.packageName}@${bundle.version}`;
}

/**
 * Creates a partial bundle name based on the available data:
 *
 * cdn-name:bundle-name@version
 * cdn-name:bundle-name
 * cdn-name
 * <empty string>
 *
 * @export
 * @param bundle The bundle definition
 * @returns A string with as much of the bundle name as we can generate
 */
export function createPartialBundleName(bundle: Partial<BundleDefinition>): string | null {
  let name = "";

  if (bundle.cdnName) {
    name = bundle.cdnName;
  }
  else {
    return null;
  }

  if (bundle.packageName) {
    name += ":" + bundle.packageName;
  }
  else {
    return name;
  }

  if (bundle.version) {
    name += "@" + bundle.version
  }

  return name;
}

/**
 * Given an asset definition, get a unique string ID for that asset.
 *
 * @export
 * @param asset
 * @returns The module name contained by the asset
 */
export function getAssetId(asset: AssetDefinition): string {
  return `${asset.packageName}@${asset.packageVersion}/${asset.assetName}`;
}

/**
 * Join 1-n regular expressions into a single regex.
 *
 * @param expressions 1-n regular expressions.
 * @returns The combined regular expression.
 */
function joinRegExp(...expressions: RegExp[]): RegExp {
  const joined = expressions.map(e => e.source).join('');
  return new RegExp(`^${joined}$`);
}

/**
 * Parse a bundle module name into a bundle definition.
 *
 * @param bundleModuleName The bundle module name to parse.
 * @returns The parsed bundle definition.
 */
export function parseBundleModuleName(bundleModuleName: string): BundleDefinition {
  const match = bundleModuleName.match(BUNDLE_MODULE_NAME_REGEX);
  if (!match) {
    throw new Error('Not a valid bundle module: ' + bundleModuleName);
  }
  return {
    cdnName: match[1],
    packageName: match[2],
    version: match[3],
  };
}