/**
 * The name of the meta tag that contains the default CDN
 * configuration URL.
 */
export const DEFAULT_CDN_NAME = '_default';

/**
 * The name of the meta tag that contains the fallback CDN
 * configuration URL.
 */
export const FALLBACK_CDN_NAME = '_fallback';


/**
 * The prefix of each HTML meta element's ID that indicates
 * that element contains CDN configuration.
 */
export const META_ELEMENT_ID_PREFIX = 'meta-cdn-config:';

export const BACKUP_META_ELEMENT_ID_PREFIX = 'meta-cdn-config-';


/**
 * Name of the meta tag that holds the string used to bust the cache.
 */
export const META_SETTING_PREFIX = "meta-cdn-setting:";

export const BACKUP_META_SETTING_PREFIX = "meta-cdn-setting-";

/**
 * Name of the query string used to bust the cache;
 */
export const CACHE_BUST_QUERY_PARAM = 'x-nimbus-cb';

/**
 * Number of milliseconds to wait after an onload event fires
 * before calling its callback. Gives a little bit of room
 * for the browser to do its thing when dynamically attaching
 * JS to the page.
 */
export const ON_LOAD_CALLBACK_DELAY = 7;

/**
 * Some CDN constructs need to be stored on the window object
 * so that they can be read by the individual bundles. This
 * value should be used as the top-level key on the window 
 * object.
 */
export const GLOBAL_OBJECT_NAMESPACE = '__NIMBUS__';

/**
 * How many times to retry fetch operations
 * TODO: This should be in athenaconf
 */
export const NUMBER_OF_FETCH_RETRIES = 2;

/**
 * meta config for session override version
 */
export const META_CDN_APP_CONFIG = "meta-cdn-config-nimbus__";

/**
 * nimbus stage cdn url
 */
export const STAGE_CDN_URL = "https://cdn-stage.nimbus.athena.io/";

/**
 * Launch Darkly Client sdk id of Nimbus stage environment(Ld project : Nimbus Testing-> Test)
 */
export const STAGE_LAUNCH_DARKLY_CLIENT_SIDE_ID = '6227ab6132cc66152e5faca8';

/**
 * Launch Darkly Client sdk id of Nimbus prod environment(Ld project : Nimbus Testing-> Production)
 */
export const PROD_LAUNCH_DARKLY_CLIENT_SIDE_ID = '6227ab6132cc66152e5faca9';